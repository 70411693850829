import React, { useState } from 'react';
import Refinansiering from '../../images/portfolio/Sparebank1/refinansiering.png';
import Forbrukslaan from '../../images/portfolio/Sparebank1/forbrukslaan.png';
import Sparkerball from '../../images/portfolio/Sparebank1/Barn-1.png';
import Kontordame from '../../images/portfolio/Sparebank1/kontordame.png';
import MannPaaVaskemaskin from '../../images/portfolio/Sparebank1/mann-vaskemaskin.png';
import Syklist from '../../images/portfolio/Sparebank1/Syklist.png';
import PortfolioPage from '../../components/PortfolioPage';
import PortfolioPageVideo from '../../components/PortfolioPageVideo';

const Sparebanken = () => {
    
    const [showVideo, setShowVideo] = useState(false); 

    const onVideoSelect = (videoUrl) => {
      setShowVideo(videoUrl)
    }
    return <PortfolioPage>
         <PortfolioPageVideo thumbnailImg={Refinansiering} url={'https://www.youtube.com/embed/e6BKZfiBtHw?si=6DqX3fSeXp4jZL92'} title="Refinansiering" />
         <div className="h-12 w-full" />
         <PortfolioPageVideo thumbnailImg={Forbrukslaan} url={'https://www.youtube.com/embed/oICLta69950?si=uWBD8pD94ilWpez5'} title="Forbrukslån" />
         <div className="h-8" />
         <div className="w-full mt-14">
            <p className="mb-6 text-xl">Sparebank 1 (Spætt film)</p>
            <p className='md:w-2/3'>I samarbeid med Spætt film, produserte vi to filmer for Sparebank 1. Den ene filmen omhandler refinansiering, og den andre handler om forbrukslån. I disse filmene blandet vi bakgrunner, filmet med kamera, og animasjon. Vi brukte de visuelle grafikkellementene fra sparebankens nettsider som utgangspunkt i karakterdesignene.  (2023)</p>
          </div>
         <div className="flex flex-wrap mt-12 justify-center align-baseline">
            <div className="flex">
                <div className="w-28 md:w-40">
                    <img src={Syklist} />
                </div>
                <div className="w-56 md:w-72">
                    <img src={Kontordame} />
                </div>
            </div>
           
            <div className="flex">
                <div className="w-56 md:w-72">
                    <img src={MannPaaVaskemaskin} /> 
                </div>
                <div className="w-56 md:w-72">
                    <img src={Sparkerball} className="sparebank-1-figur-4" />
                </div>
            </div>
          
         </div>
    </PortfolioPage>

}

export default Sparebanken;